import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";



const Features = ({earning,path}) => {
  const data=earning?.Data
  const plan=path=="MovieDetails"?data?.movie_access:data?.series_name?.series_type
  const FeaturesData = [
  
   
    plan=="TVOD"&&{
      id: "2",
      subTitle: (earning?.total_earning).toFixed(2),
      title: "Gross Profit",
      title1:"."
    
    },
    plan=="TVOD"&&{
      id: "2",
      subTitle: (earning?.tax_deduction).toFixed(2),
      title: "Taxes",
      title1:"(Deduction)"
    
    },
    plan=="TVOD"&&{
      id: "2",
      subTitle: (earning?.profit_amount).toFixed(2),
      title: "Profit",
      title1:"(After Tax)"
    
    },
    plan=="TVOD"&& data?.movie_ownership=="Collaborator"&&{
      id: "3",
    subTitle:(earning?.admin_profit).toFixed(2),
      title: "Net Profit Share",
      title1:"(Gurjar OTT)"
    
    },
    plan=="TVOD"&&data?.movie_ownership=="Collaborator"&&{
      id: "4",
      subTitle: (earning?.distributor_profit).toFixed(2),
      title: "Net Profit Share",
      title1:"(Collaborator)"
 
    }
   
    
  ];
  return (
    <>
      <Grid
        container item
        justifyContent="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{mt:0}}
      >
        {FeaturesData.filter(e=>e).map((feature) => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={feature.id} >
            <Link to={feature.link} style={{ textDecoration: 'none' }}>
            <Card
              sx={{
                background: "#fff",
                borderRadius: "10px",
                p: "15px 20px",
                mb: "15px",
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  
                }}
              >
                <Box>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 14, fontWeight: 700 }}
                 textAlign={"center"}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 12, fontWeight: 400 }}
                 textAlign={"center"}
                  >
                    {feature.title1}
                  </Typography>
                  <Typography variant="p"  fontSize={16} fontWeight={700}  textAlign={"center"} width={"100%"} display={"block"}>
                    <span >{  "₹" +" "+feature.subTitle  }</span>
                  </Typography>
                </Box>

                
              </Box>

            
            </Card></Link>
          </Grid>
        ))}
        
      </Grid>
    </>
  );
};

export default Features;
