import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
  subscription_create,
  subscription_delete,
  subscription_update,
  all_subscription_list,
} from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Popup from "./../utils/Popup";
import { all_country_list } from "./../../actions/Masters/country";
export default function Subscription() {
  const user = useSelector((state) => state.layout.profile);

  const dispatch = useDispatch();
  const rights = useSelector((state) => state.layout.rights);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [result, setResult] = useState(undefined);
  const [content, setPopupContent] = useState("");
  const [position, setPosition] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Subscriptions" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && {
        ...JSON.parse(location?.state?.form),
        // content_value0: "Yes",
        // content_value1: "1",
        // content_value2: "720p",
        // content_value3: "Yes",
        // content_value4: "Yes",
      }) || {
        content_value0: "Yes",
        content_value1: "1",
        content_value2: "1",
        content_value3: "720p",
        content_value4: "Yes",
        content_value5: "Yes",
      }
    );
    setIsEdit(path == "EditSubscriptions");
    // setFormStructure([...tempFormStruct])
  }, [location]);
  const tempTableData = {
    tableTitle: "Subscriptions",
    //  deleteRecord: subscription_delete,
    disableDelete: true,
    updateRecord: subscription_update,
    deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "plan_name",
        label: "Plan Name",
      },
      {
        id: "duration",
        label: "Duration",
      },
      {
        id: "price_org",
        label: "Price(₹)",
      },
      {
        id: "restriction_no_of_device",
        label: "Device Limit",
      },
      // {
      //   id: "profile_limit",
      //   label: "Profile Limit",
      // },
      {
        id: "status",
        label: "Status",
      },
      {
        id: "edit",
        label: "Update",
        access:  "true",
      },
    ],
    tableBody: [
      {
        id: 0,
        plan_name: "Movie",
        select_duration: "Landscape",
        price: "2",
        status: "Active",
        edit: 0,
      },
    ],
    filterColumn: [
      {
        id: "1",
        title: "Country",
        name: "country_list",
        options: [],
      },
    ],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);

  const countries = useSelector((state) => state?.masters?.country);

  const tempFormStruct =  [
    {
      id: "1",
      type: "inputBox",
      title: "Plan Name",
      name: "plan_name",
      maxLength: "20",
      regex: /^[a-zA-Z\s]+$/,
      required: true,
    },
    {
      id: "2",
      type: "inputBox",
      title: "Select Duration",
      name: "duration",
      // options: ["Yearly", "Monthly", "Semi Quaterly", "Quaterly"],
      required: true,
      disabled:true
    },
    // {
    //   id: "4",
    //   type: "inputBox",
    //   title: "Allowed Device Limit",
    //   regex: /^[0-9\.]+$/,
    //   maxLength: "1",
    //   name: "restricrt_no_of_device",

    //   required: true,
    // },
    // {
    //   id: "2",
    //   type: "select",
    //   title: "Select Max Resolution",
    //   name: "max_resolution",
    //   options: ["360p", "480p", "720p", "1080p"],
    //   required: true,
    // },

    // {
    // 	id: "4",
    // 	type: "inputBox",
    // 	title: "Allowed Profile Limit",
    // 	regex:/^[0-9\.]+$/,
    // maxLength:"1",
    // 	name: "profile_limit",
    // },
    {
      id: "23",
      type: "button",
      title: (
        <>
          <AddIcon sx={{ color: "#fff !important" }} />
          Add country
        </>
      ),
      handleClick: "",
      align: "left",
      forceShow: true,
    },
    {
      id: "2",
      type: "inputBox",
      title: "Select Country",
      name: "country0",
      options: [],
      disabled: true,
    },
    // {
    // 	id: "3",
    // 	type: "inputBox",
    // 	regex:/^[0-9\.]+$/,
    // maxLength:"6",
    // size: "5",
    // 	title: "Price",
    // 	name: "price",
    // },
    {
      // id: "3",
      // type: "inputBox",
      // regex: /^[0-9]*\.[0-9]{2}$/,
      // maxLength: "6",
      // size: "5",
      // title: "Price",
      // name: "price0",
      // required:true
      id: "24",
      type: "inputBox",
      size: "5",
      title: "Price",
      name: "price0",
      symbol: "",
      regex: /^[0-9\.]+$/,
      maxLength: "6",
      required: true,
    },
    {
      id: "21",
      type: "select",
      title: "Select Country",
      name: "country1",
      // options: temp[5]["options"],
      // required:true
    },
    {
      id: "24",
      type: "inputBox",
      size: "5",
      title: "Price",
      name: "price1",
      symbol: "",
      regex: /^[0-9\.]+$/,
      maxLength: "6",
      // required:true
    },
    {
      id: "23",
      type: "button",
      title: <RemoveIcon sx={{ color: "#fff !important" }} />,

      align: "right",
      size: ".5",
      padding: "2px 2px",
      // position: randomName,
      forceShow: true,
      handleClick: (e) => {
        setOpen(true);
        // setPosition(randomName);
        setPopupContent("Do you want to remove this country from this movie?");
      },
    },
    {
      id: "5",
      type: "description",
      title: "Description",
      name: "description",
	    display:"none"
    },
    {
      id: "6",
      type: "headind_ad",
      title: "Watch All Content (Movies, Series)",
      margin: "10px",
      size: "3",
    },
    {
      id: "23",
      type: "toggle",
      // title: "Audio Language",
      name: "content_value0",
      default: "Yes",
      size: "9",
      required: true,

      options: [
        { value: "Yes", color: "success" },
        { value: "No", color: "danger" },
      ],
    },
    {
      id: "6",
      type: "headind_ad",
      title: "Max Logged in Device",
      margin: "10px",
      size: "3",
    },
    {
      id: "23",
      type: "toggle",
      // title: "Audio Language",
      name: "content_value1",
      default: "1",
      size: "9",
      required: true,

      options: [
        { value: "1", color: "success" },
        { value: "2", color: "success" },
        { value: "3", color: "success" },
        { value: "4", color: "success" },
      ],
    },
    {
      id: "6",
      type: "headind_ad",
      title: "Single Movie watch Limit",
      margin: "10px",
      size: "3",
    },
    {
      id: "23",
      type: "toggle",
      // title: "Audio Language",
      name: "content_value2",
      default: "1",
      size: "9",
      required: true,

      options: [
        { value: "1", color: "success" },
        { value: "2", color: "success" },
        { value: "3", color: "success" },
        { value: "Unlimited", color: "success" },
      ],
    },
    {
      id: "6",
      type: "headind_ad",
      title: "Max Video Quality",
      margin: "10px",
      size: "3",
    },
    {
      id: "23",
      type: "toggle",
      // title: "Audio Language",
      name: "content_value3",
      default: "720p",
      size: "9",
      required: true,
      options: [
        { value: "1080p", color: "success" },
        { value: "720p", color: "success" },
        { value: "480p", color: "success" },
        { value: "360p", color: "success" },
      ],
    },
    {
      id: "6",
      type: "headind_ad",
      title: "Ad free Movies & Series",
      margin: "10px",
      size: "3",
    },
    {
      id: "23",
      type: "toggle",
      // title: "Audio Language",
      name: "content_value4",
      default: "Yes",
      size: "9",
      options: [
        { value: "Yes", color: "success" },
        { value: "No", color: "danger" },
      ],
    },
    {
      id: "6",
      type: "headind_ad",
      title: "View Movies & Series (Unlimited Time)",
      margin: "10px",
      size: "3",
    },
    {
      id: "23",
      type: "toggle",
      // title: "Audio Language",
      name: "content_value5",
      default: "Yes",
      size: "9",
      options: [
        { value: "Yes", color: "success" },

        { value: "No", color: "danger" },
      ],
    },
    {
      id: "7",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  const handleClick = () => {
    if (formStructure.length - 19 < countries?.data.length * 3) {
      const temp = formStructure;
      const randomName = (formStructure.length - 19 ) / 3 + 1;
// countries?.statuscode == 200 ? [...new Set(countries?.data?.map((ele, index) => Object.keys(form).map((key) => key.includes("country") && form[key] != ele.country_name && ele.country_name)).flat())] : [],
      temp.splice(
        temp.length - 14,
        0,
        {
          id: "21",
          isTemp : true,
          type: "select",
          title: "Select Country",
          name: "country" + randomName,
          options: temp[5]["options"],
          required: true,
        },
        {
          id: "24",
          type: "inputBox",
          size: "5",
          title: "Price",
          isTemp : true,
          name: "price" + randomName,
          symbol: "",
          regex: /^[0-9\.]+$/,
          maxLength: "6",
          required: true,
        },
        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          isTemp : true,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: (e) => {
            setOpen(true);
            setPosition(randomName);
            setPopupContent(
              "Do you want to remove this country from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpen(true);
      setPopupContent("You can add max 5 advertisement in a movie");
    }
  };
  useMemo(() => {

    var tempForm = formStructure;
  
    for (let index = 0; index < tempForm.length; index++) {
      if(tempForm[index]["isTemp"]){
        tempForm.splice(index,1)
        index--
      }
      
    }

    
    tempForm[2]["handleClick"] = handleClick;
    setFormStructure(tempForm);
    
    if (isEdit) {
      setView("create_new");
      if (form?.country_price.length - 1 > (formStructure.length - 19) / 3)
        form?.country_price.map(
          (ele) =>
            form?.country_price.length - 1 > (formStructure.length - 19) / 3 &&
            handleClick()
        );
      // setFormStructure([...tempFormStruct]);


    } else {
      setView("view_all");
      setForm({
        content_value0: "Yes",
        content_value1: "1",
        content_value2: "1",
        content_value3: "720p",
        content_value4: "Yes",
        content_value5: "Yes",
      });
    }
    
  }, [isEdit]);
  useMemo(() => {
    const temp = form;
    temp["country0"] = "India";
    setForm({ ...temp });
  }, [form?.country0, countries]);
  console.log(countries,"vbvv")
  useMemo(() => {
    if (countries?.statuscode == 200) {
      const tempForm = formStructure;
      tempForm[2]["handleClick"] = handleClick;
      tempForm[5]["options"] = countries?.data?.map(
        (ele, index) => ele.country_name
      );
      setFormStructure([...tempForm]);
      const temptable = tableData;
      temptable.filterColumn[0]["options"] = countries?.data?.map(
        (ele, index) => ele.country_name
      );

      setTableData({ ...temptable });
    }
  }, [countries , isEdit]);
  useMemo(() => {
    const country_list = {};
    countries?.data?.map((ele, index) => {
      country_list[ele.country_name] = ele.country_currency_symbol;
    });
    countries?.data?.map((ele, index) => {
      if (form?.["country" + index] != undefined) {
        const temp = formStructure;
        temp.map((ele, index2) => {
          if (ele.name == "price" + index) {
            temp[index2]["symbol"] = country_list[form?.["country" + index]];
          }
          if (
            ele.type == "select" &&
            ele.name.includes("country") &&
            temp[index2]["options"]?.indexOf(form?.["country" + index]) >= 0
          ) {
            temp[index2]["options"].splice(
              temp[index2]["options"].indexOf(form?.["country" + index]),
              1
            );
          }
        });
        setFormStructure([...temp]);
      }
    });
  }, [form]);

  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  useEffect(() => {
    dispatch(all_subscription_list());
    dispatch(all_country_list());
  }, []);
  useMemo(() => {
    if (subscriptions?.statuscode == 200) {
      const temp = tableData;
	  const main_content = [];
	  subscriptions?.data.map((ele) => {
		const content_file = {};
		ele?.content && ele?.content?.map((value,index)=>{
			if(value?.content_value=="true"){
			content_file["content_value" + index] = "Yes"

			}
		else if(value?.content_value=="false"){
				content_file["content_value" + index] = "No"
	
				}
			else{
			content_file["content_value" + index] = value?.content_value

			}
		})
		main_content.push(content_file)
	  })
      const country_list = [];
      const countries_string_list = [];
      subscriptions?.data.map((ele) => {
        const temp_country = {};
        var temp_string_country = "";
        ele?.country_price?.map((country, index) => {
          temp_country["country" + index] = country.country_name;
          temp_country["price" + index] = country.price;
          temp_string_country =
            temp_string_country + country.country_name + " ";
        });
        countries_string_list.push(temp_string_country);
        country_list.push(temp_country);
      });
      temp.tableBody = subscriptions?.data.map((ele, index) => ({
		
        ...ele,
        price_org:parseFloat(ele.country_price[0]?.price).toFixed(2) ,
        ...country_list[index],
		...main_content[index],
        country_list: countries_string_list[index],
      }));

      setTableData({ ...temp });
    }
  }, [subscriptions]);


  const formTitle = isEdit ? "Edit Subscription" : "Create Subscription";
  const message = useSelector((state) => state.layout.message);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    const temp_movie_category = form;
    temp_movie_category["content0"] = "Watch All Content (Movies, Series)";
    temp_movie_category["content1"] = "Max Logged in Device";
    temp_movie_category["content2"] = "Single Movie watch Limit";

    temp_movie_category["content3"] = "Max Video Quality";
    temp_movie_category["content4"] = "Ad free Movies & Series";
    temp_movie_category["content5"] = "View Movies & Series (Unlimited Time)";

    temp_movie_category["tempcontent_value0"] = form["content_value0"];
    temp_movie_category["tempcontent_value1"] = form["content_value1"];
    temp_movie_category["tempcontent_value2"] = form["content_value2"];
    temp_movie_category["tempcontent_value3"] = form["content_value3"];
    temp_movie_category["tempcontent_value4"] = form["content_value4"];
    temp_movie_category["tempcontent_value5"] = form["content_value5"];
    temp_movie_category["max_movie_watch_count"] = form["tempcontent_value2"]
    temp_movie_category["restriction_no_of_device"] = form["tempcontent_value1"];
    temp_movie_category["max_resolution"] = form["tempcontent_value3"];
    temp_movie_category["content_value0"] =
      form?.["content_value0"] !== "Yes" ? "false" : "true";
    temp_movie_category["content_value4"] =
      form?.["content_value4"] !== "Yes" ? "false" : "true";
    temp_movie_category["content_value5"] =
      form?.["content_value5"] !== "Yes" ? "false" : "true";
    setForm({
      ...temp_movie_category,
    });

    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(subscription_update(data));
    } else {
      dispatch(subscription_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setFormStructure([...tempFormStruct]);
      setIsEdit(false);
      setForm({
        // content_value0: "Yes",
        // content_value1: "1",
        // content_value2: "720p",
        // content_value3: "Yes",
        // content_value4: "Yes",
      });
      setTimeout(() => {
        dispatch(all_subscription_list());
        navigate("/Subscriptions/Subscriptions/", {
          state: { view: "view_all" },
        });
        setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [message]);
  useMemo(() => {
    if (result && position) {
      const temp = formStructure;

      temp.map((value, index) => {
        if (value.position == position) {
          const tempForm = form;
          tempForm["country" + value.position] = "";
          tempForm["price" + value.position] = "";
          setForm({ ...tempForm });
          temp.splice(index - 2, 3);
        }
      });
      setFormStructure([...temp]);
      setPosition(undefined);
    }
    setResult(undefined);
  }, [result]);
  return (
    <>
      {/* <ViewChange setForm={setForm} setView={setView} access={rights?.["Masters"]?.["create"]=="true"} setIsEdit={setIsEdit} view={view} isEdit={isEdit}/> */}

      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          formTitle={formTitle}
          key={"Form"}
          handleSubmit={handleSubmit}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Subscriptions/EditSubscriptions/"}
        />
      )}
    </>
  );
}
