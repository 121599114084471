import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
import Episode from "../components/WebSeries/Episode/Episode";

function WebSeriesModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Episode" element={<Layout children={Episode()} />} />
        <Route
          path="/CreateEpisode"
          element={<Layout children={Episode()} />}
        />
        <Route
          path="/EditEpisode"
          element={<Layout children={Episode()} />}
        />
        <Route
          path="/Episode/EpisodeDetails"
          element={<Layout children={ProductDetails()} />}
        />
      </Routes>
    </div>
  );
}

export default WebSeriesModule;
