

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function WebSeriesModule() {
  return (
    <div className="App">
    
<Routes>

      </Routes>
    </div>
  );
}

export default WebSeriesModule;
